import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Home, NotFound} from './pages';
import {SCREEN_URLS} from './config/constants';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={SCREEN_URLS.HOME} component={Home} />

        <Route component={NotFound}/>
      </Switch>
    </Router>
  );
}

export default App;
