import React from 'react'

import {GOOGLE_PLAY_ICON, APPLE_STORE_ICON, LOGO, DRIVER_LOGO} from '../config/images';

const APPS = {
    APP_STORE_3DMAIL: "https://apps.apple.com/us/app/3dmail/id1564162800",
    APP_STORE_3DMAIL_COURIER: "https://apps.apple.com/us/app/3dmail-courier/id1564162833",
    GOOGLE_PLAY_3DMAIL: "https://play.google.com/store/apps/details?id=com.getdeliveryapp",
    GOOGLE_PLAY_3DMAIL_COURIER: "https://play.google.com/store/apps/details?id=com.drivergetdelivery"
}

const About = () => {

    const openApp = (appUrl) => {
        window.location.href = appUrl;
    }

    return (
        <div className="container mx-auto mb-12 lg:mb-24 text-md md:text-xl lg:text-2xl">
            <div className="grid grid-cols-1 gap-4 place-items-center mx-10">

                <div className="flex justify-center items-end space-x-5">
                    <img alt="" src={LOGO} style={{height: 50}} />
                    <h1 className="text-3xl text-center font-bold text-gold dir-rtl">3DMAIL</h1>
                </div>
                
                <h3 className="text-lg text-center">להורדת האפלקציה לחץ על הפלטפורמה המתאימה</h3>
                <div>
                    <div className="cursor-pointer" onClick={() => openApp(APPS.GOOGLE_PLAY_3DMAIL)}>
                        <img src={GOOGLE_PLAY_ICON} alt="" className="h-32" style={{width: 300}}/>
                    </div>
                    <div className="cursor-pointer" onClick={() => openApp(APPS.APP_STORE_3DMAIL)}>
                        <img src={APPLE_STORE_ICON} alt="" className="h-24 mx-auto" style={{width: 260}}/>
                    </div>
                </div>

                <div className="flex justify-center items-center space-x-5 mt-10">
                    <img alt="" src={DRIVER_LOGO} style={{height: 30}} />
                    <h1 className="mt-10 text-3xl text-center font-bold text-gold dir-rtl">3DMAIL Courier</h1>
                </div>
                
                <h3 className="text-lg text-center">להורדת האפלקציה לחץ על הפלטפורמה המתאימה</h3>

                <div>
                    <div className="cursor-pointer" onClick={() => openApp(APPS.GOOGLE_PLAY_3DMAIL_COURIER)}>
                        <img src={GOOGLE_PLAY_ICON} alt="" className="h-32" style={{width: 300}}/>
                    </div>
                    <div className="cursor-pointer" onClick={() => openApp(APPS.APP_STORE_3DMAIL_COURIER)}>
                        <img src={APPLE_STORE_ICON} alt="" className="h-24 mx-auto" style={{width: 260}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
