
export const HttpRequestTypes = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
}

export const HttpRequest = async (url, params = null, type = HttpRequestTypes.GET, token = null) => {

    let res = null;

    if(type === HttpRequestTypes.GET) {

        if(token){
            res = await fetch(url, GetRequestBody(type, params, token));
        } else {
            res = await fetch(url);
        }
    } else {
        res = await fetch(url, GetRequestBody(type, params, token));
    }

    if(res && res.status === 200){
        return await res.json();
    } else {
        return null;
    }
}

const GetRequestBody = (type, body, token) => {
    let req = GetRequestHeaders(type, token);

    if(body){
        req["body"] = JSON.stringify(body);
    }

    return req;
}

const GetRequestHeaders = (type, token) => {
    if(token){
        return {
            method: type,
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token
            }
        }
    } else {
        return {
            method: type,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
}


export const UploadImage = async (url, data) => {
    try{
      
        let options = {
          method: HttpRequestTypes.POST,
          headers: {},
          body: data
        };
  
        let res = await fetch(url, options);
  
        if(res.status === 200){
          return await res.json();
        }else{
          return null;
        }
        
    }catch(e){
      console.log(e);
      return null;
    }
  }