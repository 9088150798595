import React from 'react'
import moment from 'moment';

const Footer = () => {
    return (
        <div className="flex flex-col-reverse bg-gray-100 mt-10 w-full text-center py-5 space-y-2 md:flex-row md:justify-between md:items-center md:space-y-0 md:px-5">
            {/* Powered By */}
            <div className="mt-3 md:mt-0">
                <label>Developed {'&'} Powered by <a href="https://infinitesol.co.il" rel="noreferrer" target="_blank" className="text-cyan-600">Infinite Sol</a></label>
            </div>

            {/* Copyright */}
            <div>
                <label>Copyright © {moment(new Date()).format("yyyy")} 3DMAIL</label>
            </div>
        </div>
    )
}

export default Footer
