import React from 'react'
import { SCREEN_URLS } from '../config/constants';
import {LOGO} from '../config/images';

const NotFound = () => {
    return (
        <div className="min-h-screen min-w-full flex flex-col justify-center items-center">
            <div className="shadow-xl px-10 py-20 text-center">
                <img src={LOGO} alt="" className="w-40 text-center mx-auto"/>
                <h1 className="text-7xl mb-10 text-gold">404</h1>
                <h2 className="font-bold">لم يتم العثور على الصفحة المطلوبة</h2>

                <div className="pt-12">
                    <button type="button" className="focus:outline-none inline-block self-start bg-gold text-white font-bold rounded-lg px-6 py-2 text-sm transform hover:scale-95 transition duration-500 hover:shadow-lg"
                        onClick={() => window.location.href = SCREEN_URLS.HOME}>
                        انقر هنا للعودة للصفحة الرئيسية
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default NotFound;
