import React from 'react'
import { useHistory } from 'react-router';
import { SCREEN_URLS } from '../config/constants';
import {LOGO} from '../config/images';

const Header = () => {

    const history = useHistory();

    const openHome = () => {
        history.replace(SCREEN_URLS.HOME)
    }

    return (
        <div className="flex flex-col md:flex-row items-center justify-between px-5 py-4 md:px-8 lg:px-10">
            {/* Left Social Media Icons */}
           <div className="w-20 h-2"></div>

            {/* Right Logo */}
            <img src={LOGO} className="w-28 md:w-32 lg:w-40 cursor-pointer mt-8 md:mt-0" alt="logo" onClick={openHome} />

            <div className="w-20 h-2"></div>
        </div>
    )
}

export default Header
