import React, { Fragment, useEffect } from 'react'
import {Header, About, Footer} from '../components';
import { ENV } from '../config/constants';
import { HttpRequest, HttpRequestTypes } from '../config/http';

const Home = () => {

    useEffect(() => {
        const querySearch = window.location.search;
        const urlParams = new URLSearchParams(querySearch);
        const id = urlParams.get("id");
        const dest = urlParams.get("dest");

        if (id && dest) {
            //Send Request to Back-End
            addAffiliateProcess(id, dest);
        }
    }, []);

    const addAffiliateProcess = async (id, dest) => {
        try{
            let url = `${ENV.API_URL}/${ENV.AFFILIATE_URL}`;
            let params = {
                id,
                dest
            };
            
            await HttpRequest(url, params, HttpRequestTypes.POST, null);
        } catch (e){
            console.log(e);
        }
    }

    return (
        <Fragment>
            <Header />
            <About />
            <Footer />
       </Fragment>
    )
}

export default Home;
