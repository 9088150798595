export const SCREEN_URLS = {
    HOME: "/"
}

export const ENV = {
    API_URL: "https://secret-falls-69948.herokuapp.com",
    AFFILIATE_URL: "api/affiliate"
}

export const IsPhoneValid = (phone) => {
    let fixedPhone = "";

    if (!phone) { return false; }

    for(let i = 0; i < phone.length; i++){
        if (phone.charAt(i) >= '0' && phone.charAt(i) <= '9'){
            fixedPhone += phone.charAt(i)
        }
    }

    return fixedPhone.length === 10 && fixedPhone.substr(0, 2) === "05";
}